module.exports = {
    // catalogUrl: "https://s3ext.gptl.ru/stac-web-free/catalog.json",
    catalogUrl: "https://stac-api.dzz.eoportal.ru/public/catalog/",
    //catalogUrl: '',
    catalogTitle: "STAC Browser",
    allowExternalAccess: false, // Must be true if catalogUrl is not given
    useTileLayerAsFallback: false,
    tileSourceTemplate: "https://stac-tiler.dzz.eoportal.ru/cog/tiles/{z}/{x}/{y}?url={url}&bidx=1&bidx=2&bidx=3",
    displayGeoTiffByDefault: false,
    buildTileUrlTemplate: null,
    stacProxyUrl: null,
    pathPrefix: "/",
    historyMode: "history",
    cardViewMode: 'list',
    showThumbnailsAsAssets: false,
    stacLint: true,
    geoTiffResolution: 128,
    redirectLegacyUrls: false,
    itemsPerPage: 8,
    maxPreviewsOnMap: 50,
    crossOriginMedia: null,
    requestHeaders: {},
    requestQueryParameters: {},
    preprocessSTAC: null,
    authConfig: null,
    rootCatalogURL: ''
};