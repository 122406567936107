<template>
  <b-form-group :label="label" :label-for="id">
    <b-input-group size="sm" class="url-group">
      <b-form-input :id="id" ref="input" :value="url" readonly class="url-input"/>
      <b-input-group-append>
        <CopyButton :copyText="url" class="url-btn copy-btn"/>
        <b-button v-if="open" :href="url" title="Open" class="url-btn open-btn"></b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
    BFormInput, BFormGroup,
    BIconArrowUpRightSquare,
    BInputGroup, BInputGroupAppend } from 'bootstrap-vue';

export default {
    name: "Share",
    components: {
        BFormGroup,
        BFormInput,
        BIconArrowUpRightSquare,
        BInputGroup,
        BInputGroupAppend,
        CopyButton: () => import('./CopyButton.vue')
    },
    props: {
        id: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        open: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss" scoped>
.popover {
    width: 100%;
    max-width: 800px;
}

.url-group {
  height: 36px;
}

.url-input {
  max-width: 676px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100% !important;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #13264B;
  background: rgba(32, 64, 128, 0.2);
}

.url-btn {
  background-color: #204080;
  border-color: transparent;
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-position: center;
}

.url-btn:hover {
  background-color: #ED1941;
  border-color: transparent;
}

.url-btn:active {
  background-color: #ED1941 !important;
  border-color: transparent !important;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.url-btn:focus {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.url-btn:focus {

}

.copy-btn {
  background-image: url("../theme/assets/img/copy.svg");
}

.open-btn {
  background-image: url("../theme/assets/img/open.svg");
}


</style>