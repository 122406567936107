import { render, staticRenderFns } from "./StacLink.vue?vue&type=template&id=4b0cd53e&scoped=true"
import script from "./StacLink.vue?vue&type=script&lang=js"
export * from "./StacLink.vue?vue&type=script&lang=js"
import style0 from "./StacLink.vue?vue&type=style&index=0&id=4b0cd53e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0cd53e",
  null
  
)

export default component.exports