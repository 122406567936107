<template>
  <b-row class="stac-footer mx-0">
<!--    <div class="w-100">
      <div class="lead stac-container footer-container" v-if="url || isSearchPage()">
        <b-button :to="'/'" size="sm" class="footer-logo">
          <img src="../theme/assets/img/logo_white.png"/>
        </b-button>
        <b-button-group class="col-5 link-group">
          <b-button class="footer-link" variant="outline-primary" :to="'/'" size="sm">
            <span class="button-label prio">Главная</span>
          </b-button>
          <b-button class="footer-link" variant="outline-primary" size="sm" v-b-toggle.sidebar title="Просмотреть">
            <span class="button-label prio">Каталог</span>
          </b-button>
          <b-button class="footer-link" variant="outline-primary" size="sm" title="Просмотреть">
            <span class="button-label prio">Поиск</span>
          </b-button>
        </b-button-group>
      </div>
    </div>-->
    <div class="footer-attribute">Powered by <b>STAC Browser</b> 3.0.0-beta.4</div>
  </b-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import StacLink from './StacLink.vue';
import { BIconArrow90degUp, BIconBook, BIconFolderSymlink, BIconSearch } from "bootstrap-vue";
import STAC from '../models/stac';
import Utils from '../utils';

export default {
  name: 'StacFooter',
  components: {
    BIconArrow90degUp,
    BIconBook,
    BIconFolderSymlink,
    BIconSearch,
    StacLink,
    Share: () => import('../components/Share.vue')
  },
  computed: {
    ...mapState(['allowSelectCatalog', 'catalogUrl', 'data', 'url', 'title']),
    ...mapGetters(['root', 'rootLink', 'parentLink', 'collectionLink', 'stacVersion', 'supportsSearch', 'toBrowserPath']),
    icon() {
      if (this.data instanceof STAC) {
        let icons = this.data.getIcons();
        if (icons.length > 0) {
          return icons[0];
        }
      }
      return null;
    },
    searchBrowserLink() {
      if (!this.allowSelectCatalog) {
        return '/search';
      }
      else if (this.supportsSearch && this.root) {
        return `/search${this.root.getBrowserPath()}`;
      }
      else if (this.supportsSearch && this.url) {
        return `/search${this.toBrowserPath(this.url)}`;
      }
      else {
        return null;
      }
    },
    containerLink() {
      // Check two cases where this page is the root...
      if (this.catalogUrl && this.url === this.catalogUrl) {
        return null;
      }
      if (this.root) {
        if (Utils.equalUrl(this.root.getAbsoluteUrl(), this.url)) {
          return null;
        }
        else {
          return {
            href: this.root.getAbsoluteUrl(),
            rel: 'root',
            title: STAC.getDisplayTitle(this.root)
          };
        }
      }
      return this.collectionLink || this.parentLink;
    }
  },
  methods: {
    isSearchPage() {
      return this.$router.currentRoute.name === 'search';
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  word-break: break-word;
}

.stac-footer {
  min-height: 62px;
  background-color: #6379A6;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0;
  margin-top: 80px;

  @media (max-width: 1279px) {
    margin-top: 60px;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
}

.footer-logo {
  background: none;
  border-color: transparent;
  padding: 0;
}

.footer-logo:hover {
  background: none;
  border-color: transparent;
}

.footer-logo:active , .footer-link:active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.footer-logo:focus , .footer-link:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.footer-link {
  border-radius: 0.2rem !important;
  max-width: 90px;
  padding: 0.25rem 0;
  border-color: transparent;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.footer-link:hover{
  background-color: transparent;
  border-color: transparent;
}



.footer-btn-group {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 218px;
}

.footer-container {
  display: flex;
  align-items: center;
}

.link-group {
  max-width: 344px;
  justify-content: space-between;
  margin-left: 115px;
  padding: 0;
}

.footer-attribute {
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  left: calc(50% - 313.38px/2);
  @media (max-width: 479px) {
    font-size: 14px;
    line-height: 17px;
    left: calc(50% - 137.1px);
  }
}

.button-label {
  color: white;
  font-weight: 500;
}
</style>