<template>
  <div class="sidebar">
    <div class="custom-header">
      <h2>Каталог</h2>
      <button class="close-header-button" v-b-toggle.sidebar></button>
    </div>
    <Loading v-if="!parents" />
    <Tree v-else-if="root && catalogUrl" :item="root" :path="parents" />
    <div v-else-if="!catalogUrl" class="no-root-warning">
      <h3>Здесь пока нет данных</h3>
      <h4>Выберите каталог на вкладке Браузер</h4>
    </div>
    <div v-if="allowSelectCatalog && catalogUrl">
      <b-button class="switch-catalog mt-4" variant="light"><router-link to="/"><b-icon-arrow-left-right /> СБРОСИТЬ</router-link></b-button>
      <p class="reset-btn-desc">Вы вернётесь на вкладку Браузер</p>
    </div>
  </div>
</template>

<script>
import { BIconArrowLeftRight } from "bootstrap-vue";
import { mapGetters, mapState } from 'vuex';
import Loading from './Loading.vue';
import Tree from './Tree.vue';

export default {
  name: 'Sidebar',
  components: {
    BIconArrowLeftRight,
    Loading,
    Tree
  },
  computed: {
    ...mapState(['allowSelectCatalog', 'parents', 'catalogUrl']),
    ...mapGetters(['root'])
  },
  async created() {
    await this.$store.dispatch('loadParents');
  }
};
</script>

<style lang="scss" scoped>
.sidebar {

  .tree.root {
    margin: 0;
    padding: 0;
  }

  .switch-catalog {
    width: 100%;
  }

  .reset-btn-desc {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin-top: 2px;
  }

  .custom-header {
    padding: 40px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0 !important;
      line-height: 36px !important;
    }

    button {
      width: 20px;
      height: 20px;
      border: none;
      background-color: transparent;
      background-image: url("../theme/assets/img/close-sidebar.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .no-root-warning {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #13264B;
      margin-bottom: 8px;
      text-align: center;
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #13264B;
      text-align: center;
    }
  }
}
</style>