<template>
  <div v-if="isOurData" class="error-container">
    <div class="error-img our-data"></div>
    <h3>Данные еще готовятся</h3>
    <p>Мы работаем над пополнением хранилища данных, попробуйте позже</p>
  </div>
  <div v-else class="error-container">
    <div class="error-img not-our-data"></div>
    <h3>Сторонний сервер не отвечает</h3>
    <p>Некоторые сторонние сервера могут не отображать данные в веб-браузере, попробуйте позже</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    isOurData: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'Sorry, the requested page could not be loaded.'
    },
    description: {
      type: String,
      default: ''
    },
    error: {
      type: [
        Object,
        Error
      ],
      default: null
    },
    id: {
      type: [
        String,
        Number
      ],
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 530px;
  position: absolute;
  top: calc(50dvh - 160px);
  left: calc((100dvw - 530px) / 2);

  .error-img {
    width: 140px;
    height: 140px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 16px;

    &.our-data {
      background-image: url("../theme/assets/img/our-data-error.svg");
    }
    &.not-our-data {
      background-image: url("../theme/assets/img/not-our-data-error.svg");
    }
  }

  h3 {
    color: #13264B;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 8px;
  }

  p {
    color: #13264B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
}
</style>